import { get, find } from 'lodash';
import { createSelector } from 'reselect';
import { EXPERIMENT_USE_SITE_MEMBERS_ID } from '@wix/communities-blog-experiments';
import isEnabled from '@wix/communities-blog-client-common/dist/src/services/is-experiment-enabled';
import { getCurrentUser } from '@wix/communities-blog-client-common/dist/src/selectors/current-user-selectors';
import { getWriters } from '../../selectors/writers-selectors';
import { isPublished } from '@wix/communities-blog-universal/dist/src/post-utils';
import { hasUnpublishedChanges } from '../../services/post-utils';
import { getExperiments } from '../../selectors/experiments-selectors';

export const getPostEditor = state => state.postEditor;

export const getPostEditorPost = createSelector(
  getPostEditor,
  postEditor => postEditor.post,
);

export const getPostEditorDraftKey = createSelector(
  getPostEditor,
  postEditor => postEditor.draftKey,
);

export const getPostEditorInitialDraftKey = createSelector(
  getPostEditor,
  postEditor => postEditor.initialDraftKey,
);

export const getPostEditorPostId = createSelector(
  getPostEditor,
  postEditor => get(postEditor, 'post._id'),
);

export const getPostEditorInitialPost = createSelector(
  getPostEditor,
  postEditor => postEditor.initialPost,
);

export const getIsEditingDraft = createSelector(
  getPostEditorInitialPost,
  initialPost => Boolean(!isPublished(initialPost) && get(initialPost, '_id')),
);

export const getIsEditingPost = createSelector(
  getPostEditorInitialPost,
  initialPost => isPublished(initialPost),
);

export const getPostEditorPostOwner = createSelector(
  getPostEditorPost,
  getCurrentUser,
  getWriters,
  getExperiments,
  (post, currentUser, writers, experiments) => {
    if (!post.owner) {
      return currentUser || {};
    }

    const isUseSiteMembersIdExperimentEnabled = isEnabled(experiments, EXPERIMENT_USE_SITE_MEMBERS_ID);

    if (isUseSiteMembersIdExperimentEnabled) {
      const draftOwnerSiteMemberId = get(post, 'draft.ownerSiteMemberId');

      if (draftOwnerSiteMemberId !== post.ownerSiteMemberId) {
        return find(writers, writer => writer.siteMemberId === draftOwnerSiteMemberId);
      }
    }

    const draftOwnerId = get(post, 'draft.ownerId');
    if (post.ownerId !== draftOwnerId) {
      return find(writers, writer => writer._id === draftOwnerId);
    }

    return post.owner;
  },
);

export const getHasUnpublishedChanges = createSelector(
  state => state,
  getPostEditorPost,
  (state, post) => hasUnpublishedChanges(post, state),
);
