import { isEmpty, flowRight, isNumber } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';

import { COMMENTS_PER_PAGE } from '@wix/communities-blog-client-common/dist/src/constants/pagination';
// import hashLinkHandler from '../../services/hash-link-handler';
import { isInPostPreview } from '../../../common/services/detect-route';
import { getRoute } from '../../../common/router/router-selectors';
import { getLastPage } from '../../../common/services/pagination';
import { resolvePostSlug } from '../../../common/services/slug';
import {
  getCommentsCount,
  getCommentsByPostId,
  getFocusCommentId,
} from '../../../comments/selectors/comment-selectors';
import {
  getIsPostLoaded,
  getIsCommentsLoaded,
  getIsRecentPostsLoaded,
} from '../../../common/store/is-loaded/is-loaded-selectors';
import { getPostByIdOrSlug } from '../../../common/selectors/post-selectors';
import { getRecentPosts } from '../../../common/selectors/recent-posts-selectors';
import { getBasicParams, isSeo, isEditor } from '../../../common/store/basic-params/basic-params-selectors';
import { scrollToComment } from '../../../common/services/scroll-to-comment';
import { simulateComponentError } from '../../../common/services/simulate-error';
import AnimatedLoader from '../../../common/components/animated-loader';
import CommentingDisabled from '../../../comments/components/commenting-disabled';
import AppLoaded from '../../../common/components/app-loaded';
import Pagination from '../../components/pagination-comments';
import PostPageLayout from '../../components/post-page-layout';
import Post from '../../../common/components/post';
import PostCommentList from '../../components/post-comment-list';
import RecentPosts from '../../../recent-posts/components/recent-posts';
import PostPageFooter from '../../components/post-page-footer';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import withAuth from '../../../common/hoc/with-auth';
import withCardBorderWidth from '../../../common/hoc/with-card-border-width';
import withDeviceType from '../../../common/hoc/with-device-type';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withTranslate from '../../../common/hoc/with-translate';
import styles from './post-page.scss';

export class PostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingPost: false,
      page: props.initialPage,
    };
  }

  componentDidMount = () => {
    simulateComponentError(this.props.basicParams, 'post-page.container');
  };

  fetchComments = (post, page) =>
    this.props.fetchComments({
      postId: post._id,
      pageSize: COMMENTS_PER_PAGE,
      page,
    });

  // onPostClosed = post => {
  //   this.props.setIsLoaded('comments', post.slug, false);
  // };

  componentDidUpdate = ({
    postSlug: prevPostSlug,
    showRecentPosts: prevShowRecentPosts,
    showComments: prevShowComments,
  }) => {
    // hashLinkHandler(this.props.location);
    const { post, postSlug, fetchRecentPosts, showRecentPosts, showComments, clearComments } = this.props;

    if (showRecentPosts && showRecentPosts !== prevShowRecentPosts) {
      fetchRecentPosts(post);
    }

    if (showComments && showComments !== prevShowComments) {
      clearComments();
      this.fetchComments(post, this.state.page);
    }

    if (!postSlug || prevPostSlug === postSlug) {
      return;
    }
  };

  setFirstCommentBottomPosition = firstCommentBottomPosition => {
    this.setState({ firstCommentBottomPosition });
  };

  renderCommentPagination = () => {
    const { commentsCount, isMobile, isCommentsLoaded } = this.props;
    if (!isMobile && isCommentsLoaded && isNumber(commentsCount)) {
      return (
        <Pagination
          page={this.state.page}
          entityCount={commentsCount}
          pageSize={COMMENTS_PER_PAGE}
          onChange={this.handlePageChange}
          createPageUrl={this.createPageUrl}
        />
      );
    }
  };

  handleCommentCreate = () => {
    const { commentsCount } = this.props;
    const lastPage = getLastPage(commentsCount, COMMENTS_PER_PAGE);
    if (this.state.page === lastPage) {
      return;
    }
    this.handlePageChange({ page: lastPage });
  };

  handlePageChange = ({ page }) => {
    const { post } = this.props;
    this.fetchComments(post, page).then(() => {
      this.setState({ page }, () => {
        scrollToComment(this.props.focusCommentId);
      });
    });
  };

  loadMore = page => this.fetchComments(this.props.post, page);

  renderCommentList = () => (
    <PostCommentList
      loadMore={this.loadMore}
      page={this.state.page}
      setFirstCommentBottomPosition={this.setFirstCommentBottomPosition}
      postSlug={resolvePostSlug(this.props.params)}
    />
  );

  renderCommentsWithForm = () =>
    this.props.isMobile ? (
      <div>
        {this.renderCommentList()}
        {this.renderCommentPagination()}
        {this.renderFooter()}
      </div>
    ) : (
      <div className={styles.comments}>
        {this.renderFooter()}
        {this.renderCommentList()}
        {this.renderCommentPagination()}
      </div>
    );

  renderFooter = () => {
    const { isBlocked, post, t, allComments } = this.props;
    if (isBlocked || post.isCommentsDisabled) {
      return (
        <CommentingDisabled message={t(isBlocked ? 'post-page.commenting-blocked' : 'post-page.commenting-disabled')} />
      );
    }

    return (
      <PostPageFooter
        onCommentCreate={this.handleCommentCreate}
        postId={post._id}
        isSticky={Boolean(this.state.firstCommentBottomPosition && allComments.length)}
        stickyThreshold={this.state.firstCommentBottomPosition}
      />
    );
  };

  render = () => {
    const {
      post,
      recentPosts,
      contentFontClassName,
      isPostLoaded,
      isCommentsLoaded,
      isRecentPostsLoaded,
      location,
      isSeo,
      showRecentPosts,
      showComments,
    } = this.props;

    return (
      <PostPageLayout className={classNames(styles.postPage, contentFontClassName)} data-hook="post-page">
        <AnimatedLoader isLoading={!isPostLoaded && isEmpty(post)}>
          {this.state.page > 1 ? null : (
            <div className={styles.post}>
              <Post key={post._id} post={post} location={location} isInPostPage />
              <AppLoaded key={post.slug} />
            </div>
          )}
          {showRecentPosts && (
            <AnimatedLoader isLoading={!isRecentPostsLoaded}>
              <div className={styles.recentPosts}>
                <RecentPosts posts={recentPosts} />
              </div>
            </AnimatedLoader>
          )}
          {!isSeo && showComments && (
            <AnimatedLoader isLoading={!isCommentsLoaded} isAnimated>
              {this.renderCommentsWithForm()}
            </AnimatedLoader>
          )}
        </AnimatedLoader>
      </PostPageLayout>
    );
  };
}

PostPage.propTypes = {
  t: PropTypes.func,
  params: PropTypes.object,
  post: PropTypes.object.isRequired,
  postSlug: PropTypes.string,

  showComments: PropTypes.bool,
  fetchComments: PropTypes.func.isRequired,
  clearComments: PropTypes.func.isRequired,
  allComments: PropTypes.array.isRequired,
  initialPage: PropTypes.number,
  commentsCount: PropTypes.number,

  showRecentPosts: PropTypes.bool,
  fetchRecentPosts: PropTypes.func.isRequired,
  recentPosts: PropTypes.array,

  isAuthenticated: PropTypes.bool,
  isBlocked: PropTypes.bool,
  isCommentsLoaded: PropTypes.bool.isRequired,
  isEditor: PropTypes.bool,
  isMobile: PropTypes.bool,
  isPostLoaded: PropTypes.bool.isRequired,
  isRecentPostsLoaded: PropTypes.bool.isRequired,
  isSeo: PropTypes.bool.isRequired,

  setIsLoaded: PropTypes.func.isRequired,

  location: PropTypes.object,

  borderWidth: PropTypes.number.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const postSlug = resolvePostSlug(ownProps.params);
  const useDraft = isInPostPreview(getRoute(state));
  let post = getPostByIdOrSlug(state, postSlug) || {};
  post = useDraft ? { ...post, ...(post.draft || {}) } : post;
  const postId = post._id;
  return {
    post,
    postSlug,

    fetchComments: actions.fetchCommentsPromisified,
    clearComments: actions.clearComments,

    allComments: getCommentsByPostId(state, postId),
    initialPage: parseInt(getBasicParams(state).page || 1, 10),
    commentsCount: getCommentsCount(state),
    focusCommentId: getFocusCommentId(state),

    fetchRecentPosts: actions.fetchRecentPosts,
    recentPosts: getRecentPosts(state, postId),

    isCommentsLoaded: getIsCommentsLoaded(state, postId),
    isEditor: isEditor(state),
    isPostLoaded: getIsPostLoaded(state, postSlug),
    isRecentPostsLoaded: getIsRecentPostsLoaded(state, postId),

    isSeo: isSeo(state),

    setIsLoaded: actions.setIsLoaded,
    basicParams: getBasicParams(state),
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withFeedMetadataSettings,
  withDeviceType,
  withFontClassName,
  withCardBorderWidth,
  withAuth,
  withTranslate,
)(PostPage);
